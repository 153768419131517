.notfound {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notfound .image {
  width: 300px;
  height: 317px;
  background: url(/public/dist/0ec391cc8e5f1a2ffed93ed8de6bd031.png) no-repeat center;
  background-size: 300px 317px;
  margin: 0 auto;
}
.notfound .desc {
  font-size: 20px;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-style: italic;
  line-height: 1.5;
  color: #171338;
  margin-top: 32px;
  text-align: center;
}
.notfound .link {
  font-size: 14px;
  font-weight: 400;
  color: #171338;
  line-height: 20px;
  margin-top: 8px;
  text-align: center;
}
.notfound .link a {
  color: #0055FF;
  cursor: pointer;
}
